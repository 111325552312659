import React from 'react'
import './style.scss'

import Img from 'gatsby-image'

function FeaturedItems(props) {
// todo Image
	const header = props.header;
	const items = props.items;
    
	const itemsMap = items?.map((node,i) => {
		const image = node.img?.localFile.childImageSharp.fluid
		return(
			<div className="single-item span-4 span-12-mobile span-6-tablet animated" key={i}>
			 	{ image ?
				 <div className="icon-positioner">
					 <Img fluid={image}
						 imgStyle={{objectFit: 'contain'}}
						 objectPosition='50% 50%'
						 loading='eager'
						 fadeIn={true}
						 className="image-1"
					 />
				 </div>
			 : null }
				<div className="box">
					<div className="text-box">
						<h3>{node.title}</h3>
						<p>{node.text}</p>
					</div>
					<div className="lp">{`0${i+1}`}</div>
				</div>
			</div>
			)
    })
	
  return (
	  <>
	  {header || items?
		<section id={`section-${props.anchor}`} className={`content c1 is-inview ${props.classes}`}>

			{props.anchor ?
				<div id={props.anchor} className="anchor"></div>
			: null}

			<div className='content-holder animated' dangerouslySetInnerHTML={{__html: header}} />
			<div className="container grid-12">
				{itemsMap}
			</div>

			<div className="bottom-wave"></div>

		</section>
		: null}
	  </>
  )
}

export default FeaturedItems