import React from 'react'
import './style.scss'

import ACFHero from "./ACF/Hero"
import ACFContent from "./ACF/Content"

import ACFMeWheelHeroCustom from "./ACF/MeWheel/HeroCustom"
import ACFMeWheelFeaturedItems from "./ACF/MeWheel/FeaturedItems"
import ACFMeWheelCTA from "./ACF/MeWheel/CTA"
import ACFMeWheelContent from "./ACF/MeWheel/Content"
import ACFMeWheelFeaturedItem from "./ACF/MeWheel/FeaturedItem"
import ACFMeWheelFeaturedItemHeader  from './ACF/MeWheel/FeaturedItemHeader'
import ACFMeWheelDoubleSided  from './ACF/MeWheel/DoubleSided'
import ACFMeWheelVideo  from './ACF/MeWheel/Video'
import ACFMeWheelNumbers  from './ACF/MeWheel/Numbers'
import ACFMeWheelGallery from './ACF/MeWheel/Gallery'

import CF7Pytanie from '../Form/CF7/Pytanie'
import CF7Jazda from '../Form/CF7/Jazda'
import Modal from '../Modal'

function Content(props) {
	let button_text_1 = null;
	let button_text_2 = null;
	const Sections = props.wordpressPage.acf.sections_page.map((section, i) => {
		
		if(section.__typename === 'WordPressAcf_hero') {

			button_text_2 = section.slides[0].button
			return (
				<div key="hero-main">
					<ACFHero { ...section } />
					{/* Point of reference for past hero observer threashold, so we can calculate if the user is past hero or not */}
					<div id="header-fold-breakpoint"></div>
				</div>
			)
		}

		if(section.__typename === 'WordPressAcf_hero_custom') {

			return (
				<>
				<ACFMeWheelHeroCustom key={section.id} { ...section } />
				<div id="header-fold-breakpoint"></div>
				</>
			)
		}
		if(section.__typename === 'WordPressAcf_featured_items') {

			return (
				<ACFMeWheelFeaturedItems key={section.id} { ...section } />
			)
		}

		if(section.__typename === 'WordPressAcf_content') {
			
			return (
				<ACFMeWheelContent key={section.id} { ...section } />
			)
		}

		if(section.__typename === 'WordPressAcf_cta') {
			button_text_1 = section.button
			return (
				<ACFMeWheelCTA key={section.id} { ...section } />
			)
		}
		if(section.__typename === 'WordPressAcf_featured_item_header') {

			return (
				<ACFMeWheelFeaturedItemHeader key={section.id} { ...section } />
			)
		}
		if(section.__typename === 'WordPressAcf_featured_item') {
			
			return (
				<ACFMeWheelFeaturedItem key={section.id} { ...section } />
			)
		}
		if(section.__typename === 'WordPressAcf_double_sided') {
			
			return (
				<ACFMeWheelDoubleSided key={section.id} { ...section } />
			)
		}
		if(section.__typename === 'WordPressAcf_gallery') {
			
			return (
				<ACFMeWheelGallery key={section.id} { ...section } />
			)
		}
		if(section.__typename === 'WordPressAcf_video') {
			
			return (
				<ACFMeWheelVideo key={section.id} { ...section } />
			)
		}
		if(section.__typename === 'WordPressAcf_numbers') {

			return (
				<ACFMeWheelNumbers key={section.id} { ...section } />
			)
		}

		return null
	})

  return (
		<>
			{Sections}
			<Modal trigger="#evg-modal-pytanie">
				<h3 className="text-center" >{button_text_1}</h3>
				<CF7Pytanie language={props.language}/>
			</Modal>
			<Modal trigger="#evg-modal-jazda">
				<h3 className="text-center" >{button_text_2}</h3>
				<CF7Jazda language={props.language}/>
			</Modal>
		</>
  )
}

export default Content