import React from 'react'
import './style.scss'

function Video(props) {
	const header = props.header
  return (
		<section id={`section-${props.anchor}`} className={`content c1 grid-12 is-inview ${props.classes}`}>

			{props.anchor ?
				<div id={props.anchor} className="anchor"></div>
			: null}

			<h2 className="span-12">{header}</h2>
			<div className="container span-12">
				{/* <div className="border top">
					<div class="marquee">
						<div>
							<div>MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel</div>
							<div>MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel</div>
						</div>
					</div>
				</div>
				<div className="border right">
					<div class="marquee">
						<div>
							<div>MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel</div>
							<div>MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel</div>
						</div>		
					</div>
				</div>
				<div className="border left">
					<div class="marquee">
						<div>
							<div>MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel</div>
							<div>MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel</div>
						</div>
					</div>
				</div>
				<div className="border bottom">
					<div class="marquee">
						<div>
							<div>MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel</div>
							<div>MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel MeWheel</div>
						</div>
					</div>
				</div> */}
				<div id="video-box" className='content-holder video-container span-12 span-12-mobile animated' >
					<iframe id="i_video" width="560" height="315" src={props.video}  frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
				</div>
			</div>
		</section>
  )
}

export default Video
