import React from 'react'
import './style.scss'


function FeaturedItemHeader(props) {
	const header = props.header
	
  return (
	  <>
	  { header ?
		<section id={`section-${props.anchor}`} className={`content c3 grid-12 is-inview ${props.classes}`}>

			{props.anchor ?
				<div id={props.anchor} className="anchor"></div>
			: null}

			<div className="span-12 span-12-tablet span-12-mobile animated">
				<div className='content-holder animated' dangerouslySetInnerHTML={{__html: header}} />
			</div>
		</section>
	  : null}
	  </>
  )
}

export default FeaturedItemHeader