import React from 'react'
import './style.scss'


function CTA(props) {
	const header = props.header
	const button = props.button
  return (
		<section id={`section-${props.anchor}`} className={`content c1 grid-12 is-inview ${props.classes}`}>

			{props.anchor ?
				<div id={props.anchor} className="anchor"></div>
			: null}

			<div className="span-9 span-12-tablet span-12-mobile animated">
				<h2>{header}</h2>
			</div>

			<div className="span-3 span-12-tablet span-12-mobile animated text-right">
				<a href="#evg-modal-pytanie" className="btn fs-125 zapytanie"><span>{button}</span></a>
			</div>

		</section>
  )
}

export default CTA