import React from 'react'

import './style.scss'


function SingleNUM(props) {
    
    const nr = props.nr;
    const title = props.title;
    const text = props.text;

    return (
        <div className="single-item span-3 span-6-tablet span-12-mobile">
            <div className="svg-box animated">
                <div className="svg-text">
                    <div className="svg-container">
                        <svg  viewBox="0 0 100 100">
                            <circle className="white-circle" cx="50" cy="50" r="30"></circle>
                            <circle data-num={props.data} className="empty-circle" cx="50" cy="50" r="30"></circle>
                        </svg>
                    </div>
                    <div className="number">
                        <h2>{nr}</h2>
                    </div>
                </div>
                <div className="text">
                    <h4>{title}</h4>
                    <p>{text}</p>
                </div>
            </div>
        </div>

    )

}

export default SingleNUM

