import React from 'react'
import './style.scss'
import Img from 'gatsby-image'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faInstagram, faGithub } from '@fortawesome/free-brands-svg-icons'

import CF7Basic from '../Form/CF7/Basic'


function Footer(props) {
	const image = props.image
	const header = props.header
	const content = props.content
	const slug = props.slug
	const lang = props.language
	let landing = false

	let translation = {}
	switch (lang) {
		case "pl_PL":
			translation = {
				question: "Gotowy na zmianę swojego życia?",
				contact: "Kontakt",
			}
			break;
		case "en_US":
			translation = {
				question: "Ready to change your life?",
				contact: "Contact details",
			}
			break;
		case "fr_FR":
			translation = {
				question: "Prêt à changer votre vie?",
				contact: "Contact",
			}
			break;
		case "de_DE":
			translation = {
				question: "Sind Sie bereit, Ihr Leben zu ändern?",
				contact: "Kontakt",
			}
			break;
		case "es_ES":
			translation = {
				question: "¿Listo para cambiar tu vida?",
				contact: "Contáctanos",
			}
			break;
	}
	if(props.slug === '/' || props.slug === '/' + lang.substring(0,2))
		landing = true

  return (
	
		<footer id='master-footer' className={landing ? 'c0 grid-12 landing' : 'c0 grid-12' }>

			{ image ?
				<Img fluid={image}
					imgStyle={{objectFit: 'cover'}}
					objectPosition='50% 50%'
					loading='eager'
					backgroundColor="#ccc"
					fadeIn={true}
				/>
			: null }

			<div className="stripes"></div>

			<div className="details padd-3 c5 fs-85 text-left">
				<div className="content-box">
					<div className="box">
						<h2>{translation.question}</h2>
					</div>
				
					<div className="box">
						{ landing ?
							null	
						:	
						<>
							<h6>{translation.contact}</h6>
							<div dangerouslySetInnerHTML={{__html: content}} />
						</>	
						}
					</div>
				</div>
			</div>
			
			<div id="contact" className="contact padd-3 c4 fs-85">
				{ landing ?
					<>
						<h2>{translation.contact}</h2>
						<p>e-mail <a href="mailto:michal@mewheel.com">michal@mewheel.com</a></p>
					</>
				:
					<CF7Basic  language={props.language}/>
				}
			</div>
			
			<div id="kontakt" className='copy c0'>
				<div className="logo-eu"></div>
				<p>Copyright &copy; 2020. Made with <FontAwesomeIcon icon={faHeart} /> by <span className='hint--rounded hint--top' data-hint='Much love!'>EvG</span>.</p>
			</div>

		</footer>
	
		
  )
}

export default Footer