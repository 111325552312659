import React from 'react'
import './style.scss'

import Img from 'gatsby-image'

function FeaturedItem(props) {
	const content = props.wysiwyg
	const image = props.img?.localFile.childImageSharp.fluid

  return (
		<>
			{ content ? 
			<section id={`section-${props.anchor}`} className={`content c1 grid-12 is-inview ${props.classes}`}>

				{props.anchor ?
					<div id={props.anchor} className="anchor"></div>
				: null}

				{ image ?
					<Img fluid={image}
						imgStyle={{objectFit: 'cover'}}
						objectPosition='50% 50%'
					/>
				: null }
				
				<div className='content-box c1'>	
					<div className='content-holder animated' dangerouslySetInnerHTML={{__html: content}} />
				</div>

			</section>
			: null }
		</>
  )
}

export default FeaturedItem