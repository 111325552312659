import React, { useState, useContext } from 'react'
import './style.scss'

import { useForm } from 'react-hook-form'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby } from '@fortawesome/free-solid-svg-icons'

import ModalContext from '../../../../context/ModalContext'

function CF7Pytanie(props) {
	const modalContext = useContext(ModalContext)

	let lang = props.language
	const { register, handleSubmit, errors, reset } = useForm()
	const [message, setMessage] = useState(false)
	const [isLoading, setLoading] = useState(false)

	const onSubmit = (form, e) => {
		e.preventDefault()

		setLoading(true)

		// Left is CF7 input name, right is ours
		let data = new FormData()
		data.append('your-fname', form.fname)
		data.append('your-lname', form.lname)
		data.append('your-phone', form.phone)
		data.append('your-email', form.email)
		data.append('your-address', form.address)
		data.append('your-message', form.message)
		
		const url = 'https://design-sentry.com/clients/mewheel/wp-json/contact-form-7/v1/contact-forms/584/feedback'
		const config = { headers: { 'Content-Type': 'multipart/form-data' } }

		axios.post(url, data, config)
			.then(response => {
					// console.log('status', response.data.status)
					// console.log('response', response.data)
					if(response.data.message) {
					 setMessage(response.data.message)
					}
					setLoading(false)
					if(response.data.status === 'mail_sent') {
						reset()

						setTimeout(() => {
							modalContext.setActive(false)
						}, 2000)
					}
				}
			)
			.catch(errors => {
					console.log(errors)
				}
			)
	}

	let translation = {}
	switch (lang) {
		case "pl_PL":
			translation = {
				fname: "Imię",
				lname: "Nazwisko",
				phone: "Telefon",
				message: "Wiadomość",
				button: "Wyślij",
				error: ['Imię jest wymagane','Nazwisko jest wymagane','Poprawny email jest wymagany','Ten numer telefonu wygląda na niepoprawny','Pole jest wymagane']
			}
			break;
		case "en_US":
			translation = {
				fname: "First name",
				lname: "Last name",
				phone: "Phone no.",
				message: "Message",
				button: "Send",
				error: ["First name is required",'Last name is required','A valid email is required','This phone looks incorrect','The field is required']
			}
			break;
		case "fr_FR":
			translation = {
				fname: "Prénom",
				lname: "Nom",
				phone: "Numéro de téléphone",
				message: "Message",
				button: "Envoyez",
				error: ["Le prénom est requis",'Le nom est requis','Un email valide est requis','Ce numéro de téléphone ne semble pas correct','Ce champ est requis']
			}
			break;
		case "de_DE":
			translation = {
				fname: "Vorname",
				lname: "Nachname",
				phone: "Telefon",
				message: "Nachricht",
				button: "Senden",
				error: ["Vorname ist erforderlich",'Nachname ist erforderlich','Eine gültige E-Mail ist erforderlich','Diese Telefonnummer scheint nicht korrekt zu sein','Dieses Feld ist erforderlich']
			}
			break;
		case "es_ES":
			translation = {
				fname: "Nombre",
				lname: "Apellido",
				phone: "Teléfono",
				message: "Mensaje",
				button: "Enviar",
				error: ['Se requiere el primer nombre','Se requiere apellido','Se requiere un e-mail válido','Este número de teléfono no parece ser correcto','El campo es obligatorio']
			}
			break;
	}
	
	return (
		<form
			className="grid-12 gap-1"
			name="contact"
			method="post"
			action="/thanks/"
			onSubmit={handleSubmit(onSubmit)}
		>

			<label className="span-6 span-12-tablet">
				<input type="text" placeholder={translation.fname} name="fname" ref={register({required: true, maxLength: 80, message: "error message"})} />
				{errors.fname && <p className="small margin-off">{translation.error[0]}</p>}
			</label>
			<label className="span-6 span-12-tablet">
				<input type="text" placeholder={translation.lname} name="lname" ref={register({required: false, maxLength: 80, message: "error message"})} />
				{errors.lname && <p className="small margin-off">{translation.error[1]}</p>}
			</label>
			<label className="span-6 span-12-tablet">
				<input type="text" placeholder="E-mail" name="email" ref={register({required: true, minLength: 6, pattern: /^\S+@\S+$/i})} />
				{errors.email && <p className="small margin-off">{translation.error[2]}</p>}
			</label>
			<label className="span-6 span-12-tablet">
				<input type="text" placeholder={translation.phone} name="phone" ref={register({required: false, maxLength: 15, message: "error message"})} />
				{errors.phone && <p className="small margin-off">{translation.error[3]}</p>}
			</label>
			<label className="span-12">
				<textarea rows="4" placeholder={translation.message} name="message" ref={register} />
			</label>

			<button className="text-center span-12 btn" type="submit"><span>{translation.button} {isLoading ? <div className="loader-spinner"><FontAwesomeIcon icon={faBaby} /></div> : null}</span></button>
			
			<p className="span-12 text-center message small">{message}</p>

		</form>
	)
}

export default CF7Pytanie