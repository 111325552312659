import React from 'react'
import './style.scss'

import Img from 'gatsby-image'

function DoubleSided(props) {

	const header = props.header;
    const items = props.items;
	const image = props.img?.localFile?.childImageSharp.fluid
	const image2 = props.img2?.localFile?.childImageSharp.fluid
	
	const itemsMapUp = items.map((node,i) => {
		if(i < 3)
		{
			return (
				<div className="single-item top animated" key={i}>
						<p>{node.text}</p>
				</div>
			)
		}
	   
	   return null
    })

	const itemsMapDown = items.map((node,i) => {
		if(i >= 3)
		{
			return (
				<div className="single-item bottom animated" key={i}>
						<p>{node.text}</p>
				</div>
			)
		}
	   
	   return null
    })
	
  return (
	  <>
	  {header && items?
		<section id={`section-${props.anchor}`} className={`content c1 is-inview  ${props.classes}`}>
				
			{props.anchor ?
				<div id={props.anchor} className="anchor"></div>
			: null}

			<div className='content-holder animated' dangerouslySetInnerHTML={{__html: header}} />
			<div className="grid-12">

				<div className="img-container img1 span-7 span-12-mobile">
					<Img fluid={image}
							imgStyle={{objectFit: 'cover'}}
							objectPosition='50% 50%'
					/>
				</div>

				<div className='grid-12 container span-5 span-12-mobile'>
					{itemsMapUp}
				</div>

				<div className='grid-12 container span-5 span-12-mobile'>
					{itemsMapDown}
				</div>

				<div className="img-container img2 span-7 span-12-mobile">
					<Img fluid={image2}
							imgStyle={{objectFit: 'cover'}}
							objectPosition='50% 50%'
					/>
				</div>
		
			</div>
		</section>
		: null}
	  </>
  )
}

export default DoubleSided