import React, { useState } from 'react'
import './style.scss'

import { useForm } from 'react-hook-form'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby } from '@fortawesome/free-solid-svg-icons'

function CF7Basic(props) {

	let lang = props.language
	const { register, handleSubmit, errors, reset } = useForm()
	const [message, setMessage] = useState(false)
	const [isLoading, setLoading] = useState(false)

	const onSubmit = (form, e) => {
		e.preventDefault()

		setLoading(true)

		// Left is CF7 input name, right is ours
		let data = new FormData()
		data.append('your-name', form.name)
		data.append('your-phone', form.phone)
		data.append('your-email', form.email)
		data.append('your-message', form.message)
		
		const url = 'https://design-sentry.com/clients/mewheel/wp-json/contact-form-7/v1/contact-forms/5/feedback'
		const config = { headers: { 'Content-Type': 'multipart/form-data' } }

		axios.post(url, data, config)
			.then(response => {
					console.log('status', response.data.status)
					console.log('response', response.data)
					if(response.data.message) {
					 setMessage(response.data.message)
					}
					setLoading(false)
					if(response.data.status === 'mail_sent') {
						reset()
					}
				}
			)
			.catch(errors => {
					console.log(errors)
				}
			)
	}

	let translation = {}
	switch (lang) {
		case "pl_PL":
			translation = {
				header: "Zadaj nam pytanie o MeWheel",
				p: "Zapraszamy do kontaktu:",
				fname: "Imię",
				lname: "Nazwisko",
				phone: "Telefon",
				message: "Wiadomość",
				button: "Wyślij",
				error: ['Imię jest wymagane','Numer telefonu jest zbyt długi','Poprawny adres email jest wymagany']
			}
			break;
		case "en_US":
			translation = {
				header: "Ask us a question about MeWheel",
				p: "Feel free to contact us:",
				fname: "First name",
				lname: "Last name",
				phone: "Phone no.",
				message: "Message",
				button: "Send",
				error: ["First name is required",'The phone number is too long',"A valid email address is required"]
			}
			break;
		case "fr_FR":
			translation = {
				header: "Posez-nous vos questions sur MeWheel",
				p: "Contactez-nous:",
				fname: "Prénom",
				lname: "Nom",
				phone: "Numéro de téléphone",
				message: "Message",
				button: "Envoyez",
				error: ["Le prénom est requis",'Le numéro de téléphone est trop long',"Une adresse email valide est exigée"]
			}
			break;
		case "de_DE":
			translation = {
				header: "Stellen Sie uns eine Frage nach MeWheel",
				p: "Kontaktieren Sie uns:",
				fname: "Vorname",
				lname: "Nachname",
				phone: "Telefon",
				message: "Nachricht",
				button: "Senden",
				error: ["Vorname ist erforderlich",'Die Telefonnummer ist zu lang','Es wird eine gültige E-Mail Adresse benötigt']

			}
			break;
		case "es_ES":
			translation = {
				header: "Pregunta por MeWheel",
				p: "Contáctanos:",
				fname: "Nombre",
				lname: "Apellido",
				phone: "Teléfono",
				message: "Mensaje",
				button: "Enviar",
				error: ['Se requiere el primer nombre','El número de teléfono es demasiado largo.',"Se requiere una dirección de correo electrónico válida"]
			}
			break;
	}
	
	return (
		<form
			name="contact"
			method="post"
			action="/thanks/"
			onSubmit={handleSubmit(onSubmit)}
		>

			<h3>{translation.header}&reg;</h3>
			<p style={{marginTop: '-1rem'}}>{translation.p}</p>

			<label>
				<input type="text" placeholder={translation.fname} name="name" ref={register({required: true, maxLength: 80, message: "error message"})} />
				{errors.name && <p className="small margin-off">{translation.error[0]}</p>}
			</label>
			<label>
				<input type="text" placeholder={translation.phone} name="phone" ref={register({required: false, maxLength: 80, message: "error message"})} />
				{errors.phone && <p className="small margin-off">{translation.error[1]}</p>}
			</label>
			<label>
				<input type="text" placeholder="e-mail" name="email" ref={register({required: true, minLength: 6, pattern: /^\S+@\S+$/i})} />
				{errors.email && <p className="small margin-off">{translation.error[2]}</p>}
			</label>
			<label>
				<textarea placeholder={translation.message} name="message" ref={register} />
			</label>

			<button type="submit">{translation.button} {isLoading ? <div className="loader-spinner"><FontAwesomeIcon icon={faBaby} /></div> : null}</button>
			
			<p className="message small">{message}</p>

		</form>
	)
}

export default CF7Basic