import React, { useState, useContext } from 'react'
import './style.scss'

import { useForm } from 'react-hook-form'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby } from '@fortawesome/free-solid-svg-icons'

import ModalContext from '../../../../context/ModalContext'

function CF7Jazda(props) {
	const modalContext = useContext(ModalContext)

	let lang = props.language
	const { register, handleSubmit, errors, reset } = useForm()
	const [message, setMessage] = useState(false)
	const [isLoading, setLoading] = useState(false)

	const onSubmit = (form, e) => {
		e.preventDefault()

		setLoading(true)

		// Left is CF7 input name, right is ours
		let data = new FormData()
		data.append('your-fname', form.fname)
		data.append('your-lname', form.lname)
		data.append('your-phone', form.phone)
		data.append('your-email', form.email)
		data.append('your-address', form.address)
		data.append('your-message', form.message)
		data.append('your-lat', form.lat)
		data.append('your-model', form.model)
		data.append('your-naped', form.naped)
		data.append('your-wiek', form.wiek)
		data.append('your-zgoda', form.zgoda)
		
		const url = 'https://design-sentry.com/clients/mewheel/wp-json/contact-form-7/v1/contact-forms/583/feedback'
		const config = { headers: { 'Content-Type': 'multipart/form-data' } }

		axios.post(url, data, config)
			.then(response => {
					// console.log('status', response.data.status)
					// console.log('response', response.data)
					if(response.data.message) {
					 setMessage(response.data.message)
					}
					setLoading(false)
					if(response.data.status === 'mail_sent') {
						reset()

						setTimeout(() => {
							modalContext.setActive(false)
						}, 2000)
					}
				}
			)
			.catch(errors => {
					console.log(errors)
				}
			)
	}
	
	let translation = {}
	switch (lang) {
		case "pl_PL":
			translation = {
				fname: "Imię",
				lname: "Nazwisko",
				phone: "Telefon",
				address: "Adres",
				age: "Twój wiek",
				years: "Od ilu lat poruszasz się na wózku?",
				model: "Twój model wózka",
				propulsion: "Czy używasz obecnie napędu? Jeśli tak – jaki?",
				agree: "Wyrazam zgodę na przetwarzanie danych osobowych w celach marketingowych",
				info: "Ze względu na ograniczoną liczbę urządzeń do jazdy próbnej MeWheel sp z o.o. zastrzega sobie prawo do wyboru osób, które będą mogły testować urządzenie. Firma nie ma obowiązku informować osób, dla których nie zorganizuje jazd próbnych, o przyczynach swojej decyzji.",
				button: "Wyślij",
				error: ['Imię jest wymagane','Nazwisko jest wymagane','Poprawny email jest wymagany','Ten numer telefonu wygląda na niepoprawny','Pole jest wymagane']

			}
			break;
		case "en_US":
			translation = {
				fname: "First name",
				lname: "Last name",
				phone: "Phone no.",
				address: "Address",
				age: "Your age",
				years: "How long have you been in a wheelchair?",
				model: "Your wheelchair model",
				drive: "Are you currently using a drive? If so, please specify.",
				agree: "I agree to have my personal data processed for marketing purposes",
				info: "Due to the limited number of test drive devices, MeWheel sp z o.o. reserves the right to select the persons who will be invited to test the device. The company is under no obligation to inform the individuals for whom it will not arrange test drives of the reasons for its decision.",
				button: "Send",
				error: ["First name is required",'Last name is required','A valid email is required','This phone looks incorrect','The field is required']

			}
			break;
		case "fr_FR":
			translation = {
				fname: "Prénom",
				lname: "Nom",
				phone: "Numéro de téléphone",
				address: "Adresse",
				age: "Age",
				years: "Depuis combien de temps vous déplacez-vous en fauteuil roulant?",
				model: "Modèle de fauteuil roulant utilisé",
				drive: "Utilisez-vous actuellement un dispositif de propulsion? Si oui, lequel?",
				agree: "J'accepte que mes données personnelles soient traitées à des fins de marketing",
				info: "En raison du nombre limité d'appareils pour les essais, MeWheel sp z o.o. se réserve le droit de sélectionner les personnes qui seront invitées à tester le dispositif. L'entreprise n’est pas tenue d'informer les personnes pour lesquelles elle n'organisera pas d'essais des raisons de sa décision.",
				button: "Envoyez",
				error: ["Le prénom est requis",'Le nom est requis','Un email valide est requis','Ce numéro de téléphone ne semble pas correct','Ce champ est requis']

			}
			break;
		case "de_DE":
			translation = {
				fname: "Vorname",
				lname: "Nachname",
				phone: "Telefon",
				address: "Adresse",
				age: "Ihr Alter",
				years: "Seit wann fahren Sie im Rollstuhl?",
				model: "Ihr Rollstuhlmodell",
				drive: "Benutzen Sie derzeit einen Antrieb? Wenn ja – welchen?",
				agree: "Ich bin mit der Verarbeitung meiner personenbezogenen Daten zu Marketingzwecken einverstanden",
				info: " Wegen der eingeschränkten Anzahl von Geräten für Probefahrten behält sich MeWheel sp. z o.o. das Recht vor, Personen zu wählen, die das Gerät anschließend testen können. Personen, für die keine Probefahrten organisiert werden, braucht die Firma ihre Entscheidung nicht erklären.",
				button: "Senden",
				error: ["Vorname ist erforderlich",'Nachname ist erforderlich','Eine gültige E-Mail ist erforderlich','Diese Telefonnummer scheint nicht korrekt zu sein','Dieses Feld ist erforderlich']

			}
			break;
		case "es_ES":
			translation = {
				fname: "Nombre",
				lname: "Apellido",
				phone: "Teléfono",
				address: "Dirección",
				age: "Tu edad",
				years: "¿Cuánto tiempo llevas en una silla de ruedas?",
				model: "Modelo de tu silla",
				drive: "¿Usas propulsión eléctrica? ¿De qué tipo?",
				agree: "Acepto el procesamiento de datos para fines de marketing",
				info: "La cantidad de dispositivos que se pueden probar es limitada. MyWheel sp. z o.o. se reserva el derecho de elegir a las personas que podrán probarlos. La compañía no tiene que informar a las personas para las que no organizará pruebas sobre la razón de su decisión.",
				button: "Enviar",
				error: ['Se requiere el primer nombre','Se requiere apellido','Se requiere un e-mail válido','Este número de teléfono no parece ser correcto','El campo es obligatorio']

			}
			break;
	}

	return (
		<form
			className="grid-12 gap-1"
			name="contact"
			method="post"
			action="/thanks/"
			onSubmit={handleSubmit(onSubmit)}
		>

			<label className="span-6 span-12-tablet">
				<input type="text" placeholder={translation.fname} name="fname" ref={register({required: true, maxLength: 80, message: "error message"})} />
				{errors.fname && <p className="small margin-off">{translation.error[0]}</p>}
			</label>
			<label className="span-6 span-12-tablet">
				<input type="text" placeholder={translation.lname} name="lname" ref={register({required: false, maxLength: 80, message: "error message"})} />
				{errors.lname && <p className="small margin-off">{translation.error[1]}</p>}
			</label>
			<label className="span-6 span-12-tablet">
				<input type="text" placeholder="E-mail" name="email" ref={register({required: true, minLength: 6, pattern: /^\S+@\S+$/i})} />
				{errors.email && <p className="small margin-off">{translation.error[2]}</p>}
			</label>
			<label className="span-6 span-12-tablet">
				<input type="text" placeholder={translation.phone}  name="phone" ref={register({required: false, maxLength: 15, message: "error message"})} />
				{errors.phone && <p className="small margin-off">{translation.error[3]}</p>}
			</label>
			<label className="span-12 span-12-tablet">
				<input type="text" placeholder={translation.address} name="address" ref={register({required: false, maxLength: 15, message: "error message"})} />
				{errors.phone && <p className="small margin-off">{translation.error[4]}</p>}
			</label>
			<label className="span-6 span-12-tablet">
				<input type="text" placeholder={translation.age} name="wiek" ref={register({required: false, message: "error message"})} />
				{errors.wiek && <p className="small margin-off">{translation.error[4]}</p>}
			</label>
			<label className="span-6 span-12-tablet">
				<input type="text" placeholder={translation.years} name="lat" ref={register({required: false, message: "error message"})} />
				{errors.lat && <p className="small margin-off">{translation.error[4]}</p>}
			</label>
			<label className="span-12 span-12-tablet">
				<input type="text" placeholder={translation.model} name="model" ref={register({required: false, message: "error message"})} />
				{errors.model && <p className="small margin-off">{translation.error[4]}</p>}
			</label>
			<label className="span-12 span-12-tablet">
				<input type="text" placeholder={translation.drive} name="naped" ref={register({required: false, message: "error message"})} />
				{errors.naped && <p className="small margin-off">{translation.error[4]}</p>}
			</label>
			<label className="span-12 span-12-tablet">
				<div className="inline">
					<input type="checkbox" name="zgoda" ref={register({required: true, message: "error message"})} />
					<p>{translation.agree}</p>
				</div>
				{errors.zgoda && <p className="small margin-off">- {translation.error[4]}</p>}
			</label>

			<p className="span-12  message small info">
				{translation.info}
			</p> 

			<button className="text-center span-12 btn" type="submit"><span>{translation.button} {isLoading ? <div className="loader-spinner"><FontAwesomeIcon icon={faBaby} /></div> : null}</span></button>
			
			<p className="span-12 text-center message small">{message}</p>
		</form>
	)
}

export default CF7Jazda