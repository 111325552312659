import React,{useState} from 'react'
import './style.scss'


import Img from 'gatsby-image'


function Content(props) {

	const [currentSlide, setcurrentSlide] = useState(0);

	const content = props.wysiwyg
	const image = props.img?.localFile.childImageSharp.fluid
	
	let slides = props.slider?.map((node, i) => {
		return (	
			<Img fluid={node.img?.localFile.childImageSharp.fluid}
			imgStyle={{objectFit: 'cover'}}
			objectPosition='50% 50%'
			className={`slide slide-${i}`}
			key={i}
		/>
		)

	})
	let numberOfSlides = props.slider?.length - 1 ;

	const nextSlide = () => {
		if(currentSlide < numberOfSlides)
		setcurrentSlide(currentSlide + 1);
	}
	const prevSlide = () =>{
		if(currentSlide > 0)
		setcurrentSlide(currentSlide - 1);
	}
  return (
		<>
			{ content ? 
			<section id={`section-${props.anchor}`} className={`content c1 grid-12 is-inview ${props.classes}`}>
				
				{props.anchor ?
					<div id={props.anchor} className="anchor"></div>
				: null}

				{ image ?
					<div className="img-positioner span-8 span-12-mobile">
						<div className="img-wrap">
							<div className={`carousel carousel-${currentSlide}`}>

								{slides}

								<Img fluid={image}
								imgStyle={{objectFit: 'cover'}}
								objectPosition='50% 50%'
								className="image"
								/>

							</div>
							<a className="prev" onClick={(e) => prevSlide()}></a>
  							<a className="next" onClick={(e) => nextSlide()}></a>
						</div>
					</div>
				: null }
				
				<div className='content-box span-4 span-12-mobile'>
					<div className='content-holder animated' dangerouslySetInnerHTML={{__html: content}} />
					<div className="item"></div>
				</div>

				<div className="wave"></div>
				<div className="wave2"></div>

			</section>
			: null }
		</>
  )
}

export default Content