import React from 'react'
import './style.scss'
import SingleNUM from './SingleNUM'

function Numbers(props) {

	const header = props.header;
	const items = props.items;
	const itemsMap = items?.map((node,i) => {
			return <SingleNUM data={i} key={i}  {...node} />
		})
	
  return (
	  <>
	  {header && items?
		<section id={`section-${props.anchor}`} className={`content c1 is-inview ${props.classes}`}>

			{props.anchor ?
				<div id={props.anchor} className="anchor"></div>
			: null}

			<div className='content-holder animated' dangerouslySetInnerHTML={{__html: header}} />

			<div className="container grid-12">
				{itemsMap}
			</div>

		</section>
		: null}
	  </>
  )
}

export default Numbers