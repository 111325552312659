import React, {useState} from 'react'
import './style.scss'

import Img from 'gatsby-image'

function Gallery(props) {

    const [currentImg, setCurrentImg] = useState(0)
    const items = props.items

    const handleClick = (e) => {
        const modal =  document.getElementById("myModal")
        if(modal.style.display === 'none') {
            setCurrentImg(e.currentTarget.tabIndex)
            modal.style.display = 'block'
        }     
        else 
            modal.style.display = 'none'
    }
	
    const imgMap = items.map( (item,index) => {

        let image = item.img?.localFile?.childImageSharp.fluid
        return (
            <div key={index} onClick={handleClick} tabIndex={index} className='img-container span-4 span-12-mobile'>
                <Img fluid={image}
                imgStyle={{objectFit: 'cover'}}
                objectPosition='50% 50%'
                />
            </div>
        )
    })
	
    return (
	  <>
	  { items?
		<section id={`section-${props.anchor}`} className={`content grid-12 c1 is-inview ${props.classes}`}>

			{props.anchor ?
				<div id={props.anchor} className="anchor"></div>
			: null}

            <div id="myModal" className="modal" onClick={handleClick}>
                    <Img fluid={items[currentImg].img?.localFile?.childImageSharp.fluid}
                    imgStyle={{objectFit: 'cover'}}
                    objectPosition='50% 50%'
                    className='modal-content' 
                    />
            </div>

            <div className="grid-12 img-positioner">
                {imgMap}
            </div>
            
		</section>
		: null}
	  </>
  )
}

export default Gallery